<template>
    <div id="bandeau" :class="{'bandeau-exit':hideCookies}">
        <div class="sct-parent">
            <div class="sct-box">
                <h5 class="txt-upcase">{{$t('titleCookies')}}</h5>
                <p>{{$t('dataCookies')['0']}}</p>
            </div>
            <div class="sct-btn">
                <a href="javascript:;" class="btn-send validCookies" v-on:click="hideBandeau">{{$t('dataCookies')['1']}}</a>
                <a href="javascript:;" class="btn-send">{{$t('dataCookies')['2']}}</a>
                <a href="javascript:;" class="btn-link" v-on:click="hideBandeau">{{$t('dataCookies')['3']}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'bandeau',
        props: {
            msg: String,
        },
        data() {
            return{
                hideCookies: false,
            }
        },
        methods: {
            hideBandeau(){
              this.hideCookies= true;
            }
        },
        mounted(){
            setTimeout(() => {
                document.getElementById("bandeau").classList.add("bandeau-entrance");
            }, 2000);
            
            /******    CREATE COOKIES     ******/
            function createCookie(name, value, days) {
                var expires;
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toGMTString();
                }
                else {
                    expires = "";
                }
                document.cookie = name + "=" + value + expires + "; path=/";
            }

            /******    GET COOKIES VALUE    ******/
            function getCookie(c_name) {
                if (document.cookie.length > 0) {
                   let c_start = document.cookie.indexOf(c_name + "=");
                    if (c_start != -1) {
                        c_start = c_start + c_name.length + 1;
                       let c_end = document.cookie.indexOf(";", c_start);
                        if (c_end == -1) {
                            c_end = document.cookie.length;
                        }
                        return unescape(document.cookie.substring(c_start, c_end));
                    }
                }
                return "";
            }

            /******    ERASE COOKIES VALUE    ******/
            // function eraseCookie(name) {
            //     document.cookie = name+'=; Max-Age=-99999999;';
            // }
            // eraseCookie("isValid")

            document.querySelector(".validCookies").addEventListener("click", function() {
                createCookie("isValid", 1, 3);
            })

            if(getCookie("isValid").length > 0){
                this.hideCookies= true;
            }
        }
    }
</script>